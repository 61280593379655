

.App {
  height: 100%;
  width: 100%;
  text-align: center;
  font-family: 'Birdseye',
    sans-serif;
}

.main-button {
  font-size: 18px;
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  will-change: box-shadow, transform;
  background: radial-gradient(100% 100% at 100% 0%, #2ed2ff 0%, #5468FF 100%);
  box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 40%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%), inset 0px -0.01em 0px rgb(58 65 111 / 50%);
  padding: 0 2em;
  border-radius: 0.3em;
  color: #fff;
  height: 2.6em;
  width: auto;
  margin-right: 20px;
  text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
  transition: box-shadow 0.15s ease, transform 0.15s ease;
}

.main-button:hover {
  box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0;
}

@media (max-width: 769px) {
  .infoPage p {
      font-size: 16px !important;
      padding-left: 10% !important;
      padding-right: 10% !important;
    }

 .infoPage ul {
  width: 100% !important;
   font-size: 16px !important;
   padding-left: 10% !important;
   padding-right: 10% !important;
 }
  
  .infoPage-container li {
    font-size: 16px;
    line-height: 2;
    display: inline-block;
}
}

.infoPage {
  width: 100%;
  height: 100%;
  color: black;
  background-image: url('./assets/minneapolis-minnesota-usa-cityscape-city-lights-skyline-7680x4320-4608.jpg');
  background-size: cover;
}

.infoPage h1 {
  margin-bottom: 2%;
}

.infoPage p{
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  line-height: 1.75;
  font-size: 20px;
}

.infoPage-wrapper {
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(1.5px);
}

.infoPage ul {
  position: relative;
  font-size: 1.25em;
  line-height: 2;
  display: inline-block;
}

.infoPage-service ul {
  width: 60%;
}

.infoPage-service li {
  padding: 0.5rem 1.5rem 1rem;
  border: solid 3px;
    border-radius: 1.5rem;
    border-color: rgb(192, 192, 192);
    background-color: rgb(192, 192, 192, 0.75);;

}

.infoPage li + li {
  margin-top: 1rem;
}

.infoPage-ContactPage {
  height: 100%;
}

.infoPage hr {
  background-color: gray;
  width: 25%;
  height: 0.1em;
  margin-left: 38%;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}


