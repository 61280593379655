@media (min-width: 300px) and (max-width: 767px) {
  .home {
      height: 70vh;
      width: 100%;
      color: white;
      background: url('../assets/minneapolis-minnesota-usa-cityscape-city-lights-skyline-7680x4320-4608.jpg');
      background-size: cover;
    }


    .MissionStatement{
      height: 100%;
    }

    .home-content-wrapper {
    margin-top: -30%;
  }
  
    .content-h1 {
      margin-top: -20%;
      font-size: 7vh;
    }
  
    .content-h3 {
      margin-top: 2%;
      font-size: 4vh;
    }
  
    .content-h4 {
      margin-top: 2%;
      font-size: 2vh;
    }

    Form {
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 20px;
    width: 80%;
    height: 90%;
    left: 10%;
  }

  .headshot {
    position: relative;
    left: 0;
    margin: 5%;
  }

  .BBBlogo {
  position: absolute;
  height: 12%;
  left: 25%;
  top: 97%;
}
}

@media (min-width: 768px) and (max-width: 1023px) {
  .home {
      height: 55vh;
      width: 100%;
      color: white;
      background: url('../assets/minneapolis-minnesota-usa-cityscape-city-lights-skyline-7680x4320-4608.jpg');
      background-size: cover;
    }

    .home-content-wrapper {
       margin-top: -20%;
    }

    .MissionStatement{
      height: 100vh;
    }

  .content-h1 {
      margin-top: -10%;
      font-size: 7vh;
    }

    .content-h3 {
      margin-top: 2%;
      font-size: 4vh;
    }
  
    .content-h4 {
      margin-top: 2%;
      font-size: 2vh;
    }

    Form {
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 20px;
    width: 80%;
    height: 90%;
    left: 10%;
  }

  .headshot {
    position: relative;
    left: 0;
    width: 40%;
    margin: 2.5%;
  }

  .BBBlogo {
  position: absolute;
  height: 12%;
  left: 39%;
  top: 90%;
}
}

@media (min-width: 1024px){
  .home {
    height: 60vh;
      width: 100%;
      color: white;
      background: url('../assets/minneapolis-minnesota-usa-cityscape-city-lights-skyline-7680x4320-4608.jpg');
      background-size: cover;
  }

  .home-content-wrapper {
    margin-top: -6%;
  }

    .content-h1 {
      margin-top: -20%;
      font-size: 9vh;
    }

    .MissionStatement {
      height: 100%;
    }

  .infoPage {
    height: 100vh;
  }

  .CustodyEvaluations {
    height: 100%;
  }

    .content-h3 {
      margin-top: 2%;
      font-size: 6vh;
    }
  
    .content-h4 {
      margin-top: 2%;
      font-size: 3vh;
    }

    Form {
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 20px;
    width: 80%;
    height: 90%;
    left: 10%;
  }

  .headshot {
  height: 50%;
  position: absolute;
  left: 2%;
}

.BBBlogo {
  position: absolute;
  height: 25%;
  left: 42%;
  top: 75%;
}

}



.blur {
    height: 100%;
    width: 100%;
    backdrop-filter: blur(1.5px);
}

.home-content-container {
  width: 100%;
  padding-top: 20vh;
}
